@layer modules, ui, base;
@layer base {
  .FooterMenuList_title__QN9R_ {
  margin-top: 0;
  margin-bottom: calc(var(--sk-space-32)/2);
  font-size: 0.875rem;
  font-weight: var(--font-weight-semibold);
}

.FooterMenuList_link__zJJdl {
  display: inline-block;
  padding: var(--sk-space-16);
  color: var(--sk-color-white);
  font-size: var(--typo-body-3-font-size);
  line-height: var(--typo-body-2-line-height);
  text-decoration: none;
}

@media (max-width: 47.999rem) { /* --small-and-less-only */
  .FooterMenuList_main__VKfN9 {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  .FooterMenuList_main__VKfN9 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .FooterMenuList_item__QZnlO {
    padding: 0 16px;
  }

  .FooterMenuList_title__QN9R_ {
    display: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .FooterMenuList_title__QN9R_::after {
    content: "";
    display: block;
    width: var(--sk-space-24);
    margin-bottom: var(--sk-space-24);
    padding-bottom: var(--sk-space-24);
    border-bottom: thin solid var(--sk-color-white);
  }

  .FooterMenuList_link__zJJdl {
    padding: 0;
  }
}

}
@layer base {
  .FooterMenu_main__7HAwT {
  padding: calc(var(--sk-space-32)/2);
  border-bottom: 1px solid #dbdbdb;
}

.FooterMenu_menuItem__desktop__TI_pe {
  display: none;
}

@media (max-width: 63.999rem) { /* --medium-and-less-only */
  .FooterMenu_menuItem__oPS7U {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .FooterMenu_menuItem__desktop__TI_pe {
    display: none;
  }
}

@media (min-width: 64rem) { /* --large-viewport */
  .FooterMenu_main__7HAwT {
    display: flex;
    padding: var(--sk-space-48) 0;
    font-size: 0.875rem;
  }

  .FooterMenu_menuItem__oPS7U {
    flex: 1 0 auto;
  }

  .FooterMenu_menuItem__desktop__TI_pe {
    display: block;
  }
}

}
